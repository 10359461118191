@use "sass:math";

$ws-map-icon-sensor-width: 26px;
$ws-map-icon-sensor-padding: 9px;
$ws-map-icon-gateway-width: 30px;
$ws-map-icon-gateway-padding: 9px;

.ws-map-icon,
.ws-map-icon-gateway,
.ws-map-icon-sensor {
  &__fa-icon {
    opacity: 60%;
    position: absolute;
  }
  span {
    height: 20px;
    position: absolute;
    width: 20px;
    &::before {
      animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
      background-color: #f2f2f2;
      border-radius: 50%;
      box-sizing: border-box;
      content: "";
      display: block;
      height: 130%;
      margin-left: -15%;
      margin-top: -15%;
      position: relative;
      width: 130%;
    }
    &::after {
      animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 1) -0.625s infinite;
      background-color: #f2f2f2;
      border-radius: 50%;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
    /* stylelint-disable */
    &.ACTIVE,
    &.CREATED {
      &::before,
      &::after {
        background-color: #326715;
      }
    }
    &.MOISTURE_0_10 {
      &::before,
      &::after {
        @apply bg-moistureState0-10;
      }
    }
    &.MOISTURE_10_15 {
      &::before,
      &::after {
        @apply bg-moistureState10-15;
      }
    }
    &.MOISTURE_15_20 {
      &::before,
      &::after {
        @apply bg-moistureState15-20;
      }
    }
    &.MOISTURE_20_25 {
      &::before,
      &::after {
        @apply bg-moistureState20-25;
      }
    }
    &.MOISTURE_25_30 {
      &::before,
      &::after {
        @apply bg-moistureState25-30;
      }
    }
    &.MOISTURE_30_35 {
      &::before,
      &::after {
        @apply bg-moistureState30-35;
      }
    }
    &.MOISTURE_35_40 {
      &::before,
      &::after {
        @apply bg-moistureState35-40;
      }
    }
    &.MOISTURE_40_100 {
      &::before,
      &::after {
        @apply bg-moistureState40-100;
      }
    }
    &.INACTIVE,
    &.INVALID {
      &::before {
        @apply bg-moistureStateInactive;
        animation: none;
        width: 0px;
        height: 0px;
      }
      &::after {
        @apply bg-moistureStateInactive;
        animation: none;
        box-shadow: none;
        transform: scale(0.9);
      }
    }
    /* stylelint-enable */
    &:hover {
      cursor: pointer;
    }
  }
}

.ws-map-icon-gateway {
  span {
    height: $ws-map-icon-gateway-width + 1px !important;
    left: -(math.div($ws-map-icon-gateway-width, 2)) !important;
    top: -(math.div($ws-map-icon-gateway-width, 2)) !important;
    width: $ws-map-icon-gateway-width + 1px !important;
  }
  .ws-map-icon {
    &__fa-icon {
      height: $ws-map-icon-gateway-width - $ws-map-icon-gateway-padding !important;
      left: math.div($ws-map-icon-gateway-padding, 2) !important;
      top: math.div($ws-map-icon-gateway-padding, 2) !important;
      width: $ws-map-icon-gateway-width - $ws-map-icon-gateway-padding !important;
    }
  }
}

.ws-map-icon-sensor {
  span {
    height: $ws-map-icon-sensor-width + 1px !important;
    left: -(math.div($ws-map-icon-sensor-width, 2)) !important;
    top: -(math.div($ws-map-icon-sensor-width, 2)) !important;
    width: $ws-map-icon-sensor-width + 1px !important;
  }
  .ws-map-icon {
    &__fa-icon {
      height: $ws-map-icon-sensor-width - $ws-map-icon-sensor-padding !important;
      left: math.div($ws-map-icon-sensor-padding, 2) !important;
      top: math.div($ws-map-icon-sensor-padding, 2) !important;
      width: $ws-map-icon-sensor-width - $ws-map-icon-sensor-padding !important;

      &.MOISTURE_0_10 {
        color: hsl(98, 73%, 20%);
      }
      &.MOISTURE_10_15 {
        color: hsl(99, 55%, 17%);
      }
      &.MOISTURE_15_20 {
        color: hsl(99, 57%, 14%);
      }
      &.MOISTURE_20_25 {
        color: hsl(99, 50%, 11%);
      }
      &.MOISTURE_25_30 {
        color: hsl(99, 60%, 8%);
      }
      &.MOISTURE_30_35 {
        color: hsl(120, 79%, 6%);
      }
      &.MOISTURE_40_100 {
        color: hsl(120, 79%, 4%);
      }
      &.INACTIVE {
        color: hsl(0, 25%, 32%);
      }
    }
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.63);
  }
  90%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}
