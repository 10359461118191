.ws-pulsating-circle {
  height: 20px;
  position: absolute;
  width: 20px;
  &::before {
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    background-color: #f2f2f2;
    border-radius: 45px;
    box-sizing: border-box;
    content: "";
    display: block;
    height: 150%;
    margin-left: -25%;
    margin-top: -25%;
    position: relative;
    width: 150%;
  }
  &::after {
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 1) -0.625s infinite;
    background-color: #f2f2f2;
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  /* stylelint-disable */
  &.ACTIVE,
  &.CREATED {
    &::before {
      background-color: #326715;
    }
    &::after {
      background-color: #326715;
    }
  }
  &.MOISTURE_0_10 {
    &::before {
      @apply bg-moistureState0-10;
    }
    &::after {
      @apply bg-moistureState0-10;
    }
  }
  &.MOISTURE_10_15 {
    &::before {
      @apply bg-moistureState10-15;
    }
    &::after {
      @apply bg-moistureState10-15;
    }
  }
  &.MOISTURE_15_20 {
    &::before {
      @apply bg-moistureState15-20;
    }
    &::after {
      @apply bg-moistureState15-20;
    }
  }
  &.MOISTURE_20_25 {
    &::before {
      @apply bg-moistureState20-25;
    }
    &::after {
      @apply bg-moistureState20-25;
    }
  }
  &.MOISTURE_25_30 {
    &::before {
      @apply bg-moistureState25-30;
    }
    &::after {
      @apply bg-moistureState25-30;
    }
  }
  &.MOISTURE_30_35 {
    &::before {
      @apply bg-moistureState30-35;
    }
    &::after {
      @apply bg-moistureState30-35;
    }
  }
  &.MOISTURE_35_40 {
    &::before {
      @apply bg-moistureState35-40;
    }
    &::after {
      @apply bg-moistureState35-40;
    }
  }
  &.MOISTURE_40_100 {
    &::before {
      @apply bg-moistureState40-100;
    }
    &::after {
      @apply bg-moistureState40-100;
    }
  }
  &.INACTIVE {
    &::before {
      animation: none;
      @apply bg-moistureStateInactive;
      height: 0px;
      width: 0px;
    }
    &::after {
      animation: none;
      @apply bg-moistureStateInactive;
      box-shadow: none;
    }
  }
  /* stylelint-enable */
  &:hover {
    cursor: pointer;
  }
  &__detach-sensor {
    animation: detach-animation;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.63);
  }
  90%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}

@keyframes detach-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    display: none;
    transform: scale(0);
  }
}

.ws-static-circle {
  height: 20px;
  position: absolute;
  width: 20px;
  &::after {
    background-color: #f2f2f2;
    border-radius: 15px;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    opacity: 80%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  /* stylelint-disable */
  &.ACTIVE,
  &.CREATED {
    &::before {
      @apply bg-brand-green;
    }
    &::after {
      @apply bg-brand-green;
    }
  }
  &.MOISTURE_0_10 {
    &::before {
      @apply bg-moistureState0-10;
    }
    &::after {
      @apply bg-moistureState0-10;
    }
  }
  &.MOISTURE_10_15 {
    &::before {
      @apply bg-moistureState10-15;
    }
    &::after {
      @apply bg-moistureState10-15;
    }
  }
  &.MOISTURE_15_20 {
    &::before {
      @apply bg-moistureState15-20;
    }
    &::after {
      @apply bg-moistureState15-20;
    }
  }
  &.MOISTURE_20_25 {
    &::before {
      @apply bg-moistureState20-25;
    }
    &::after {
      @apply bg-moistureState20-25;
    }
  }
  &.MOISTURE_30_35 {
    &::before {
      @apply bg-moistureState30-35;
    }
    &::after {
      @apply bg-moistureState30-35;
    }
  }
  &.INACTIVE {
    &::before {
      animation: none;
      @apply bg-moistureStateInactive;
      height: 0px;
      width: 0px;
    }
    &::after {
      animation: none;
      @apply bg-moistureStateInactive;
      box-shadow: none;
    }
  }
  /* stylelint-enable */
  &:hover {
    cursor: pointer;
  }
}

.image-box-hovering {
  transform: scale(2);
  transition: ease-out 0.25s;
}

.active-draggable-sensor {
  animation: active-animation 1.5s infinite;
}

@keyframes active-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
