.address-autosuggest {
  &__container {
    background-color: #fff;
    width: 100%;
  }
  &__suggestions-list {
    border: 1px solid #ccc;
    border-radius: 2px;
    list-style: None;
    margin: 0;
    padding: 0;
  }
  &__suggestion {
    padding: 10px;
    &:hover {
      cursor: pointer;
    }
  }
  &__suggestion--highlighted {
    background-color: #ccc;
  }
}
