.react-datepicker-wrapper {
  @apply w-full h-10;
}

.react-datepicker__input-container {
  @apply h-full relative;
}

.react-datepicker-popper {
  @apply z-40 w-auto text-sm bg-white shadow px-3 py-2 border-2 border-gray-200 rounded;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__month {
  @apply flex flex-col;
}

.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold text-brand-gray;
}

.react-datepicker__week {
  @apply flex justify-around;
}

.react-datepicker__day-names {
  @apply flex justify-around text-brand-gray-light-1 font-medium text-center text-xs;
}

.react-datepicker__day-name {
  @apply w-8 h-8 flex items-center justify-center py-1 rounded-full;
}

.react-datepicker__day {
  @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-gray-700 rounded cursor-pointer;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
  @apply text-brand-gray-light-3;
}

.react-datepicker__day--in-range {
  @apply bg-brand-lime;
}

.react-datepicker__day--selected {
  @apply bg-brand-green text-white;
}

.react-datepicker__day--range-start {
  @apply bg-brand-green text-white hover:text-gray-700 hover:bg-white;
}

.react-datepicker__day--range-end {
  @apply bg-brand-green text-white hover:text-gray-700 hover:bg-white;
}
.react-datepicker__aria-live {
  @apply hidden;
}

/* Hide the calendar icon in the date input */
input[type="date"]::-webkit-calendar-picker-indicator {
  @apply hidden;
  -webkit-appearance: none;
}

/* Hide the number input spinner */
input[type="number"] {
  @apply appearance-none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
}
