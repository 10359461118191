.ws-add-button {
  border-radius: 30px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  font-size: 60px;
  height: 60px;
  line-height: 30px;
  padding-top: 14px;
  width: 60px;
  &:active {
    transform: translate(0, 1px);
  }
}
