.react-autosuggest {
  &__container {
    position: relative;
  }
  &__input {
    width: 100%;
    &--focused {
      outline: none;
    }
    &--open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  &__suggestions-container {
    display: none;
  }
  &__suggestions-container {
    &--open {
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 2px;
      border-top: 0;
      display: block;
      font-size: 16px;
      font-weight: 300;
      max-height: 80vh;
      overflow: hidden;
      overflow-y: scroll;
      z-index: 2;
    }
  }
  &__suggestions-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  &__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }
  &__suggestion--highlighted {
    background-color: #ddd;
  }
}
