.ws-edit-button {
  @apply text-brand-green;
  background: #fff;
  border-radius: 30px;
  box-shadow:
    0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
  display: block;
  font-size: 30px;
  font-weight: 100;
  height: 60px;
  line-height: 30px;
  padding-top: 14px;
  text-align: center;
  transition: background-color 0.5s ease;
  width: 60px;
  z-index: 10;
  &:hover {
    background-color: #f8f8f8;
    cursor: pointer;
  }
  &:active {
    transform: translate(0, 1px);
  }
  &__bottom-right {
    bottom: 100px;
    position: fixed;
    right: 16px;
  }
}
