// Woodsense
$woodsense-color-primary: #532c0f;
$woodsense-color-secondary: #a1512c;
$woodsense-color-accent: #e69b24;
$woodsense-color-light-gold: #d7b972;

$ws-sensor-status-moisture-0-10: #a8d88d;
$ws-sensor-status-moisture-10-15: #93bd7b;
$ws-sensor-status-moisture-15-20: #7fa36a;
$ws-sensor-status-moisture-20-25: #6b895a;
$ws-sensor-status-moisture-25-30: #58714a;
$ws-sensor-status-moisture-30-35: #45593a;
$ws-sensor-status-moisture-35-40: #34422b;
$ws-sensor-status-moisture-40-100: #232d1d;
$ws-sensor-status-inactive: #f2f2f2;

$success: #326715;
