@import "~styles/variables/colors";

.map {
  &-div {
    height: 60vh;
    min-height: 300px;
  }
  &-full-view {
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
  }
}

$pulse-color-moisture-0-10: $ws-sensor-status-moisture-0-10;
$pulse-color-moisture-10-15: $ws-sensor-status-moisture-10-15;
$pulse-color-moisture-15-20: $ws-sensor-status-moisture-15-20;
$pulse-color-moisture-20-25: $ws-sensor-status-moisture-20-25;
$pulse-color-moisture-25-30: $ws-sensor-status-moisture-25-30;
$pulse-color-moisture-30-35: $ws-sensor-status-moisture-30-35;
$pulse-color-moisture-35-40: $ws-sensor-status-moisture-35-40;
$pulse-color-moisture-40-100: $ws-sensor-status-moisture-40-100;

$pulse-color-good: rgb(75, 210, 143);
$pulse-color-medium: rgb(0, 149, 255);
$pulse-color-bad: rgb(255, 77, 77);
$pulse-color-inactive: rgb(169, 176, 181);

.ws-map-marker,
.ws-sensor-map-marker,
.ws-gateway-map-marker {
  animation: pulsate 2s ease-out;
  animation-iteration-count: infinite;
  stroke: $pulse-color-medium;

  /* stylelint-disable */
  &.MOISTURE_0_10 {
    fill: $pulse-color-moisture-0-10;
    stroke: $pulse-color-moisture-0-10;
  }
  &.MOISTURE_10_15 {
    fill: $pulse-color-moisture-10-15;
    stroke: $pulse-color-moisture-10-15;
  }
  &.MOISTURE_15_20 {
    fill: $pulse-color-moisture-15-20;
    stroke: $pulse-color-moisture-15-20;
  }
  &.MOISTURE_20_25 {
    fill: $pulse-color-moisture-20-25;
    stroke: $pulse-color-moisture-20-25;
  }
  &.MOISTURE_25_30 {
    fill: $pulse-color-moisture-25-30;
    stroke: $pulse-color-moisture-25-30;
  }
  &.MOISTURE_30_35 {
    fill: $pulse-color-moisture-30-35;
    stroke: $pulse-color-moisture-30-35;
  }
  &.MOISTURE_35_40 {
    fill: $pulse-color-moisture-35-40;
    stroke: $pulse-color-moisture-35-40;
  }
  &.MOISTURE_40_100 {
    fill: $pulse-color-moisture-40-100;
    stroke: $pulse-color-moisture-40-100;
  }
  &.ACTIVE,
  &.CREATED {
    fill: $pulse-color-good;
    stroke: $pulse-color-good;
  }
  &.INACTIVE,
  &.INVALID {
    animation: none;
    fill: $pulse-color-inactive;
    stroke: $pulse-color-inactive;
    stroke-opacity: 0;
    stroke-width: 3;
  }
  /* stylelint-enable */
}

@keyframes pulsate {
  0% {
    stroke-opacity: 0;
    stroke-width: 3;
  }
  50% {
    stroke-opacity: 0.8;
    stroke-width: 5;
  }
  100% {
    stroke-opacity: 0;
    stroke-width: 3;
  }
}
