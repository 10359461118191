// See
// https://github.com/moroshko/react-autosuggest#theme-optional
.global-search-div {
  .react-autosuggest {
    &__suggestion {
      position: relative;
    }
    &__suggestions-container {
      &--open {
        max-width: 80vw;
        min-width: 300px;
        position: absolute;
        top: 58px;
        width: 100%;
      }
    }
    &__input {
      width: 100%;
      &:focus {
        border: 0;
        box-shadow: none;
      }
    }
  }
}
